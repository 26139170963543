<template>
  <div>
    <v-row align="center" justify="center">
      <v-col style="margin-top: 15%" cols="12">
        <v-progress-circular
          :size="130"
          :width="8"
          color="#1976d2"
          indeterminate
        ></v-progress-circular>
        <p class="mt-5">{{ $translate("general.loading") }} </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import alert from "@/mixins/alert";
import Api from "@/utils/api";
import moment from "moment";

export default {
  data() {
    return {
      infoToken: {
        document: "",
      },
    };
  },
  methods: {
    validateToken() {
      var token = this.$route.query.token;
      Api.noAuth()
        .login(token)
        .then((res) => {
          if (res.data.cod == 0) {
            if (res.data.data == 0) {
              localStorage.setItem("token", atob(token));
              localStorage.setItem("tokenB64", token);
              this.saveLog();
              this.$router.push({ name: "home" });
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    saveLog() {
      this.getToken();
      let dispositivo;
      if (screen.width < 1024) {
        dispositivo = "Mobile";
      } else {
        dispositivo = "Desktop";
      }
      const record = {
        userid: this.infoToken.document,
        appid: "79",
        appname: "Login Okan",
        date: moment().format("YYYY-MM-DD"),
        dispositivo,
      };
    },
  },
  mounted() {
    this.validateToken();
  },
};
</script>
